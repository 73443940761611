import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"


const Privacy = ({ intl }) => (
  <Layout>
    <SEO lang={intl.locale} title={intl.formatMessage({ id: "privacy.title" })} />
    <h1>
      <FormattedMessage id="privacy.title" />
    </h1> 
    <h3>
      <FormattedMessage id="privacy.intro.title" />
    </h3>
    <p>
      <FormattedMessage id="privacy.intro.content" />
    </p>
    <h3>
      <FormattedMessage id="privacy.data.title" />
    </h3>
    <p>
      <FormattedMessage id="privacy.data.content1" />
    </p>
    <ul>
      <li><FormattedMessage id="privacy.data.point1" /></li>
      <li><FormattedMessage id="privacy.data.point2" /></li>
      <li><FormattedMessage id="privacy.data.point3" /></li>
      <li><FormattedMessage id="privacy.data.point4" /></li>
    </ul>
    <p>
      <FormattedMessage id="privacy.data.content2" />
    </p>

    <h3>
      <FormattedMessage id="privacy.time.title" />
    </h3>
    <p>
      <FormattedMessage id="privacy.time.content" />
    </p>

    <h3>
      <FormattedMessage id="privacy.anonymous.title" />
    </h3>
    <p>
      <FormattedMessage id="privacy.anonymous.content" />
    </p>

    <h3>
      <FormattedMessage id="privacy.websites.title" />
    </h3>
    <p>
      <FormattedMessage id="privacy.websites.content" />
    </p>

    <h3>
      <FormattedMessage id="privacy.storage.title" />
    </h3>
    <p>
      <FormattedMessage id="privacy.storage.content" />
    </p>

    <h3>
      <FormattedMessage id="privacy.right.title" />
    </h3>
    <p>
      <FormattedMessage id="privacy.right.content" />
    </p>

    <h3>
      <FormattedMessage id="privacy.contact.title" />
    </h3>
    <p>
      <FormattedMessage id="privacy.contact.content1" />
    </p>
    <p>
      <FormattedMessage id="privacy.contact.content2" />
    </p>

    <h3>
      <FormattedMessage id="privacy.change.title" />
    </h3>
    <p>
      <FormattedMessage id="privacy.change.content" />
    </p>

    <p>
      <br />
      <FormattedMessage id="privacy.version" />
    </p>


  </Layout>
)

export default injectIntl(Privacy)
